<template>
  <v-list-group
    v-model="tournament.active"
    active-class="color3--text"
    append-icon="fas fa-angle-down"
    class="tList"
  >
    <template v-slot:activator>
      <v-list-item-title class="pa-0">{{tournament.name}}</v-list-item-title>
    </template>
    <!-- Home -->
    <v-list-item
      :to="{ name: 'session-admin', params: {tournamentId: tournament.id} }"
      router
      exact
      exact-active-class="color3--text"
      v-if="view.admin"
    >
      <v-list-item-content>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Tournament Brochure -->
    <v-list-item
      :to="`/session/${tournament.id}?tab=location`"
      router
      exact
      active-class="color3--text"
    >
      <v-list-item-content>
        <v-list-item-title>Information</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- Schedule -->
    <v-list-item
        :to="`/session/${tournament.id}?tab=schedule`"
        router
        exact
        active-class="color3--text"
        v-if="view.admin"
      >
        <v-list-item-content>
          <v-list-item-title>Schedule</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <!-- Players -->
    <v-list-item
      v-if="tournament.isShowcase"
      :to="{ name: 'session-players', params: {tournamentId: tournament.id} }"
      router
      exact
      exact-active-class="color3--text"
    >
      <v-list-item-content>
        <v-list-item-title>Players</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- Divisions -->
    <template v-for="division in divisionTabs">
      <!-- Division Home -->
      <v-list-item
        :key="division.id"
        router
        :to="{name: 'division-nav', params: {tournamentId: tournament.id, divisionId: division.id}}"
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="division.loading && !division.hydrated">
            <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
          </v-list-item-title>
          <v-list-item-title v-else>
            {{ division.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="!division.isCS && division.loading">
          <v-progress-circular indeterminate color="color3" size="24"></v-progress-circular>
        </v-list-item-action>
        <v-list-item-action v-if="division.isCS">
          <v-chip small :color="chipColor" v-if="view.admin || division.showTeams">
            {{division.teamCount}}
          </v-chip>
          <v-icon v-else small>fas fa-eye-slash</v-icon>
        </v-list-item-action>
      </v-list-item>

      <template v-if="!(division.loading && !division.hydrated)">
        <v-list-item
          v-for="tab in division.tabs"
          :to="tab.to"
          :key="tab.key"
          router
          exact
          exact-active-class="color3--text"
        >
          <v-list-item-action class="mr-2"></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{tab.text}}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="tab.count || !tab.public">
            <v-chip small :color="chipColor" v-if="tab.count">
              {{tab.count}}
            </v-chip>
            <v-icon v-else small>fas fa-eye-slash</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </template>
</v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHsp } from '@/classes/Dark'

export default {
  computed: {
    ...mapGetters(['user', 'tournament', 'view', 'color2IsDark', 'theme']),
    chipColor () {
      const hsp1 = getHsp(this.theme.color2)
      const hsp2 = getHsp(this.theme.color2Text)
      return hsp1 < hsp2 ? 'color2 color2Text--text' : 'color2Text color2--text'
    },
    publicDivisions () {
      var divs = this.tournament && this.tournament.publicDivisions
      var jProps = this.tournament && this.tournament.jProps && this.tournament.jProps.divisions
      return divs && jProps ? divs.filter(f => !(jProps[f.id] && jProps[f.id].hide)) : divs
    },
    home () {
      return this.view.admin ? 'division-home' : 'division-teams'
    },
    divisionId () {
      return +this.$route.params.divisionId
    },
    division () {
      return this.divisionId && this.tournament.divisions.find(f => f.id === this.divisionId)
    },
    divisionTabs () {
      return this.division ? [this.division].map(d => {
        return {
          loading: d.loading,
          hydrated: d.hydrated,
          id: d.id,
          name: d.name,
          show: true,
          tabs: [
            {
              text: 'Settings',
              to: { name: 'division-home', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin,
              key: `settings_${d.id}`
            },
            {
              text: `${d.teamLabel}${this.view.admin && d.pools.length > 0 && d.poollessTeamIds.length > 0 ? '*' : ''}`,
              to: { name: 'division-teams', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin || !d.isCS,
              key: `teams_${d.id}`,
              count: this.view.admin || d.showTeams ? d.teamCount : null,
              public: d.showTeams
            },
            {
              text: 'Waitlist',
              to: { name: 'division-waitlist', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: d.waitlistTeams.length && !d.isCS && !(this.view.public && d.hideWaitlist),
              key: `waitlist_${d.id}`,
              count: this.view.admin || d.showTeams ? d.waitlistTeams.length : null,
              public: d.showTeams
            }
          ].filter(f => f.show),
          rounds: d.isCS ? null : d.days
        }
      }).filter(f => f.show) : []
    }
  }
}
</script>
<style>
.tList .v-list-group__header {
  padding-left: 8px !important;
  padding-right: 2px !important;
}
</style>
